.projects-wrapper {
  height: 100vh;
}
.projects-array {
    color: rgb(36, 161, 239);
}
.map-method {
    color: rgb(234, 234, 147);
}
.outer-parantheses {
   color: rgb(215, 199, 26);
}
.inner-parantheses {
  color: rgb(193, 34, 193);
}
.project-param {
  color: #8ccfe8;
}
.details-btn {
  background: radial-gradient(circle, #8a8b8e, #aeb2b5, #d8dadc);
  color: rgb(37, 36, 36);
  border: 1px solid black;
  border-radius: 5px;
  width: 100px;
  padding: 3px;
  font-size: smaller;
  font-weight: bolder;
  text-shadow: 1px 1px #767171;
  float: inline-end;
  margin-right: 2em;
  cursor: pointer;
}
.details-btn:hover {
  background: radial-gradient(circle, #d8dadc, #aeb2b5, #8a8b8e);
  border: 2px solid rgb(255, 119, 0);
  color: rgb(255, 119, 0);
}
.modal-fullscreen .modal-content {
  background: linear-gradient(to right, #151515, #161714);
  font-family: 'Consolas', 'Courier New', monospace;
}
#contained-modal-title-vcenter {
    color: rgb(49, 183, 127);
}
.long-description {
    color: #8ccfe8;
}  
.modal-close-btn {
  background: radial-gradient(circle, #8a8b8e, #aeb2b5, #d8dadc);
  color: rgb(37, 36, 36);
  border: 1px solid black;
  border-radius: 5px;
  width: 100px;
  padding: 3px;
  font-size: smaller;
  font-weight: bolder;
  text-shadow: 1px 1px #767171;
  float: inline-end;
  margin-right: 2em;
  cursor: pointer;
}
.modal-close-btn:hover {
  background: radial-gradient(circle, #d8dadc, #aeb2b5, #8a8b8e);
  color: rgb(255, 119, 0);
}
.stack {
  background-color: #193756;
  border-radius: 5px;
  width: max-content;
}
.technology {
  font-size: smaller;
  color: turquoise;
}
.modal-footer {
  border-top: none;
}
.code-img {
  width: 100%;
}
.project-info {
  width: 25%;
  display: flex;
  flex-direction: column;
  background-color: #161714;
}
.accordion__button {
  background-color: #161714;
  color: whitesmoke;
}
.accordion__button:hover {
  background-color: #31322e;
  color: whitesmoke;
}
.accordion__panel {
  font-size: smaller;
}
.custom-zoom [data-rmiz-modal-overlay="hidden"] {
  background-color: rgba(27, 27, 37, 0);
}
.custom-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: rgb(24, 25, 32);
}
.custom-zoom [data-rmiz-btn-unzoom] {
  background-color: #262429;
  color: #000;
}
.custom-zoom [data-rmiz-btn-unzoom]:focus-visible {
  outline-offset: 0.4rem;
  outline: 0.2rem solid #1e1b21;
}
.visit {
  color: #c6ccd0;
}
.visit:hover {
  background-color: rgb(66, 63, 63);
  padding: 1.5px;
  border-radius: 5%;
}
@media screen and (max-width: 1100px) {
  .d-flex.modal-body {
    flex-direction: column;
  }
  .project-info {
    width: 100%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 808px) {
  .stack-wrapper {
    flex-direction: column;
  }
}
@media screen and (max-width: 404px) {
  .carousel-indicators {
    position: relative;
  }
}