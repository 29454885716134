#about {
    height: 100vh;
}
.nav-link {
    color: whitesmoke;
}
.nav-link:hover {
    color: #d7b95e;

}
.tab-content {
    font-size: 0.8rem;
}
.cv-file {
    font-size: x-small;
    color: #A321CE;
}
.pub-wrapper {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
.accordion-button {
    font-size: 0.9rem;
}
.accordion-body {
    text-align: left;
}
.link-to-article {
    color: rgb(240, 178, 77);
    font-weight: bolder;
    border-radius: 5px;
    padding: 5px;
    background-color: #CFE2FF;
}
.link-to-article:hover {
    color: rgb(244, 204, 140);
    background-color: #98b0d4;

}
.article-views:hover {
    opacity: 0.7;
}