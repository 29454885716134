#hero {
    height: 100vh;
    font-size:larger;
}
.my-pic {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.my-pic.visible {
  opacity: 1;
}
.my-pic:hover {
    transform: scale(3); 
    opacity: 0.3;
    transition: transform 0.8s ease; 
  }
@media screen and (max-width: 570px) {
    .scrolldown {
      flex-direction: column-reverse;
    }
    .animated-text {
        margin-left: 10px;
        margin-right: 10px;
    }
}