.App {
  position: relative;
  text-align: center;
  background: linear-gradient(to right, #232421, #1D1E19);
  overflow-x: hidden;
  color: whitesmoke;
}

.header {
  height: 15vh;
}

.wrapper {
  height: 80vh;
}