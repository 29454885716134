.hero {
  height: 100vh;
}
code {
  font-size: 10px;
  margin-left: 5px;
  color: #81dbfc;
}
.sublime {
  border-radius: 26px 26px 0px 0px;
  color: turquoise;
  font-size: 10px;
  height: 15px;
  width: 100%;
  background-color: #2c3e50;
}

.mac {
  width: 350px;
  height: 300px;
  border-radius: 26px;
  border: 14px solid #dce3e8;
  background-color: #34495e;
  margin: 0 auto 0 auto;
}

.stand {
  width: 60px;
  height: 50px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin: 0 auto 0 auto;
  border-bottom: 50px solid #dce3e8;
}

.skip {
  cursor: pointer;
  font-size: smaller;
}

@media screen and (max-width: 375px) {
  .mac {
    width: 320px;
  }
}

@media screen and (max-width: 330px) {
  .mac {
    width: 300px;
  }
}