.contact-wrapper {
    height: 100vh;
}

.cont {
    margin: 5px;
    padding: 7px;
    font-size: 12px;
    font-family: arial;
    background-color: rgba(255,253,231 ,0.1);
    border: 1px solid rgba(236,239,241 ,0.5);
    border-radius: 5px;
}


.cont:focus {
    transition: 0.5s;
    background-color: rgba(255,249,196 ,0.2);
    border-color: #FFD54F;
    outline: 0;
}


#submit_button {
    width: 150px;
    height: 35px;
    font-size: 16px;
    color: #F5F5F5;
    background-color: #F9A825;
    border: 0px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
}

#submit_button:hover {
    transition: 0.5s;
    background-color: rgba(255,143,0 ,1);
}

textarea::-webkit-input-placeholder {
color: rgba(245,245,245 ,1) !important;
}
 
textarea:-moz-placeholder { /* Firefox 18- */
color: rgba(245,245,245 ,1) !important;
opacity: 1;  
}
 
textarea::-moz-placeholder {  /* Firefox 19+ */
color: rgba(245,245,245 ,1) !important;
opacity: 1;  
}
 
textarea:-ms-input-placeholder {  
color: rgba(245,245,245 ,1) !important;  
}

input::-webkit-input-placeholder {
color: rgba(245,245,245 ,1) !important;
}
 
input:-moz-placeholder { /* Firefox 18- */
color: rgba(245,245,245 ,1) !important;
opacity: 1;  
}
 
input::-moz-placeholder {  /* Firefox 19+ */
color: rgba(245,245,245 ,1) !important;
opacity: 1;  
}
 
input:-ms-input-placeholder {  
color: rgba(245,245,245 ,1) !important;  
}

#boxing {
    font-family: arial;
    color: rgba(245,245,245 ,1);
    font-size: 13px;
}

.cont:focus {
    color: white;
}

.cont {
    color: white;
}

.fade.toast.show {
    background-color: rgb(49, 183, 127);
    max-width: fit-content;
    
}

.toast-body  {
    border-radius: 10%;
    max-width: fit-content;
    background-color: rgb(49, 183, 127);
    color: black;
}

@media screen and (max-width: 555px) {
    .submit-and-toast {
        flex-direction: column;
    }
    .fade.toast.show {
        margin-top: 15px;
    }
}

@media screen and (max-width: 390px) {
    .contact-form {
        width: 90%;
    }
    .cont {
        margin: 3px;
        padding: 4px;
    }
    .submit-and-toast {
        align-items: center;
    }
}